import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import consent from './consent';

const Context = createContext();

export const useConsent = () => useContext(Context);

const storageKey = 'cookie-consent';

const storeConsent = (settings) => {
  window.localStorage.setItem(storageKey, JSON.stringify(settings));
};

const ConsentProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(null);

  useEffect(() => {
    const accepted = window.localStorage.getItem(storageKey);
    if (!accepted) {
      // not accepted cookies yet, open the bar
      setOpen(true);
    } else {
      // we accepted cookies, consent to GTM
      let savedSettings;
      try {
        savedSettings = JSON.parse(accepted);
      } catch (err) {
        console.error('Error parsing cookie consent JSON');
        return;
      }
      consent(savedSettings);
    }
  }, []);

  useEffect(() => {
    if (values === null) {
      return;
    }
    consent(values);
    storeConsent(values);
    setOpen(false);
  }, [values]);

  const value = useMemo(() => ({
    storeConsent,
    open,
    setOpen,
    setValues,
  }), [
    open,
    setOpen,
    setValues,
  ]);
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default ConsentProvider;
