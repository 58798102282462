import { wrapRootElement } from './gatsby-ssr';

export { wrapRootElement };

export const onRouteUpdate = () => {
  if (process.env.GATSBY_ENV !== 'production') {
    return;
  }
  // track facebook pixel if present
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'ViewContent');
  }
};
