const consent = ({
  preferences,
  statistics,
  marketing,
}) => {
  if (!window.dataLayer) {
    console.error('Didn\'t find "dataLayer" on window');
    return;
  }
  if (preferences) {
    window.dataLayer.push({ event: 'cookieconsent_preferences' });
  }
  if (statistics) {
    window.dataLayer.push({ event: 'cookieconsent_statistics' });
  }
  if (marketing) {
    window.dataLayer.push({ event: 'cookieconsent_marketing' });
  }
};

export default consent;
