/* eslint-disable import/prefer-default-export */

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createGlobalStyle } from 'styled-components';
import { wrapPageElement } from './gatsby-ssr';

// Would we need this?
// export const onServiceWorkerUpdateReady = () => window.location.reload(true);

const GrecaptchaBadgeStyling = createGlobalStyle`
  /* For hidding the recaptcha badge */
  .grecaptcha-badge {
    display: none;
  }
`;

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LdwsIEUAAAAAKcNuaqlTqALEDf_NSgaqnshfnhn"
    language="nl"
    useRecaptchaNet
  >
    <GrecaptchaBadgeStyling />
    {element}
  </GoogleReCaptchaProvider>
);

export { wrapPageElement };
