/* eslint-disable import/prefer-default-export */

import React from 'react';
import Provider from './src/context';

export const wrapRootElement = ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
